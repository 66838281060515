import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// 294.ink 부분
import InkMain from "./294.ink/Main";
import Main from "./294.ink/ktheme/Main";
import Help from "./294.ink/ktheme/Help";
import Download from "./294.ink/ktheme/Download";

// 294.moe 부분
import MoeMain from "./294.moe/Main";
import Info from "./294.moe/Info";
import Error from "./294.moe/404";

const AppRouter = () => {
  const hostname = window.location.hostname;

  useEffect(() => {
    if (hostname === "294.moe") {
      document.body.id = "moe";
    } else {
      document.body.id = "ink";
    }
  }, [hostname]);

  return (
        <Routes>
        {hostname === "294.moe" ? (
            <>
                <Route path="/" element={<MoeMain />} />
                <Route path="/info" element={<Info />} />
                <Route path="/404" element={<Error />} />

                <Route path="*" element={<Navigate to="/404" />} />
            </>
        ) : (
            <>
                {/* 메인페이지 */}
                <Route path="/" element={<InkMain />} />

                {/* Project RGB 페이지 */}
                <Route path="/theme" element={<Main />} />
                <Route path="/theme/help" element={<Help />} />
                <Route path="/theme/download/:themeId" element={<Download />} />
            </>
        )}
      </Routes>
  );
}

export default AppRouter;