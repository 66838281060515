import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css';

const KthemeHeader = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <header className="ktheme-header">
                <div className="logo">
                    <h1>Project RGB</h1>
                </div>
                <div className="hamburger" onClick={toggleMenu}>
                    ☰
                </div>
                <nav className={`ktheme-sidebar ${menuOpen ? 'open' : ''}`}>
                    <ul>
                        <li><Link to="/theme" onClick={closeMenu}>프로젝트 소개</Link></li>
                        <li><Link to="/theme/download/rgb-code" onClick={closeMenu}>RGB-Code</Link></li>
                        <li><Link to="/theme/download/rgb-select" onClick={closeMenu}>RGB-Select</Link></li>
                        <li><Link to="/theme/help" onClick={closeMenu}>적용법</Link></li>
                    </ul>
                </nav>
            </header>

            {/* 메뉴가 열렸을 때만 오버레이 표시 */}
            {menuOpen && <div className="ktheme-overlay" onClick={closeMenu}></div>}
        </>
    );
};

export default KthemeHeader;
