import React from 'react';
import { Helmet } from 'react-helmet-async';
import KthemeHeader from './Header';
import KthemeFooter from './Footer';
import '../css/style.css';

const KthemeHelp = () => {
    return (
        <>
        <Helmet>
            <title>Project RGB - 적용법</title>
        </Helmet>
        <div className="ktheme-main">
            <KthemeHeader />
            <div className="phone-frame">
                <div className="phone-content">
                    <h2>카카오톡 테마 적용법</h2>
                </div>
                <KthemeFooter />
            </div>
        </div>
        </>
    );
};

export default KthemeHelp;
