import React from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async";
import './css/style.css';
import Logo from './img/logo.svg';

const Error = () => {
    return (
        <>
            <Helmet>
              <title>이마월 - 에러</title>
              <meta property="og:title" content="이마월 - 에러" />
            </Helmet>
            <div className="error-page">
              <div className="logo-no-animation">
                <img src={Logo} alt="294 Logo" />
              </div>

              <h2 className="error-heading">404 Not Found</h2>
              <p className="error-subheading">
                현재 서버가 꺼져있거나, 잘못된 접근입니다. <br />
              </p>
              <span className="error-paragraph">여기, 버튼을 하나 준비했습니다.</span>
              <Link to="/" className="error-button">
                <span>
                  <i className="fas fa-home-lg"></i>홈으로
                </span>
              </Link>
            </div>
        </>
    );
}
export default Error;