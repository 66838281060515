import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigate 훅 import
import KthemeHeader from './Header';
import KthemeFooter from './Footer';
import '../css/style.css';
import { Helmet } from 'react-helmet-async';

const KthemeMain = () => {
    const navigate = useNavigate(); // useNavigate 훅 사용

    const handleMenuClick = (menu) => {
        if (menu === 'about') {
            navigate('/theme'); // 소개 페이지로 이동
        } else if (menu === 'download') {
            navigate('/theme/download/rgb-code'); // 다운로드 페이지로 이동
        } else if (menu === 'help') {
            navigate('/theme/help'); // 적용법 페이지로 이동
        }
    };

    return (
        <>
            <Helmet>
                <title>294 - Project RGB</title>
            </Helmet>
            <div className="ktheme-main">
                <KthemeHeader />

                {/* 휴대폰 프레임 */}
                <div className="phone-frame">
                    <div className="phone-content">

                        {/* PC 화면에서 애플리케이션 아이콘처럼 보이는 메뉴들 */}
                        <div className="app-icons-container">
                            <div
                                className="app-icon"
                                onClick={() => handleMenuClick('about')}
                            >
                                <p>소개</p>
                            </div>
                            <div
                                className="app-icon"
                                onClick={() => handleMenuClick('download')}
                            >
                                <p>다운로드</p>
                            </div>
                            <div
                                className="app-icon"
                                onClick={() => handleMenuClick('help')}
                            >
                                <p>적용법</p>
                            </div>
                        </div>

                    </div>
                    <KthemeFooter />
                </div>
            </div>
        </>
    );
};

export default KthemeMain;
