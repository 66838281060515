import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import $ from 'jquery';
import './UnderConstruction.css';
import firefly from '../294.moe/util/firefly';
import Logo from './img/logo.svg';

const Main = () => {

    // 파티클 이펙트
    useEffect(() => {
        firefly();

        return () => {
            if ($.firefly && $.firefly.pause) {
                $.firefly.pause();
            }
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>294</title>
            </Helmet>

            <div className="construction-page">

                <div className="logo-no-animation">
                    <img src={Logo} alt="294 Logo" />
                </div>

                <div className="sign">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="200"
                        height="200"
                        viewBox="0 0 170 170"
                        className="pickaxe-animation"
                    >
                        <g>
                            {/* 곡괭이 손잡이 부분 */}
                            <rect
                                x="95"
                                y="40"
                                width="10"
                                height="90"
                                fill="var(--color-smoothblue)"
                                className="pickaxe-handle"
                            />
                            {/* 곡괭이 머리 부분: 더 길게 수정, 초승달 모양 */}
                            <path
                                d="M40,50 Q100,10 160,50 Q100,40 40,50"
                                fill="var(--color-lightgreen)"
                                className="pickaxe-head"
                            />
                        </g>
                    </svg>
                    <p>공사중...</p>
                </div>
            </div>
        </>
    );
};

export default Main;
