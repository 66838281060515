import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/style.css';

const KthemeFooter = () => {
    const navigate = useNavigate();

    return (
        <footer className="ktheme-footer">
            <div className="footer-buttons">
                <button onClick={() => navigate('/theme')}>홈</button>
                <button onClick={() => navigate(-1)}>뒤로가기</button>
            </div>
            <p>© 2024 Project RGB</p>
        </footer>
    );
};

export default KthemeFooter;
