import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import KthemeHeader from './Header';
import KthemeFooter from './Footer';
import '../css/style.css';

const themes = {
    'rgb-code': [
        { mainColor: '#000', subColor: '#FFF', name: 'RGB Code 0' },
        { mainColor: '#FF0000', subColor: '#00FF00', name: 'RGB Code 1' },
    ],
    'rgb-select': [
        { mainColor: '#800080', subColor: '#FFC0CB', name: 'RGB Select 0' },
        { mainColor: '#FFA500', subColor: '#008000', name: 'RGB Select 1' },
    ],
};

const getContrastingColor = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 125 ? '#000' : '#FFF';
};

const KthemeRelease = () => {
    const { themeId } = useParams();
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);

    const handleThemeSelect = (theme) => {
        setSelectedTheme(theme);
        setPopupVisible(false);
    };

    const textColor = selectedTheme
        ? getContrastingColor(selectedTheme.mainColor)
        : '#FFF';

    const availableThemes = themes[themeId] || [];

    return (
        <>
            <Helmet>
                <title>Project RGB - 다운로드</title>
            </Helmet>
            <div className="ktheme-main">
                <KthemeHeader />
                <div className="phone-frame">
                    <div className="phone-content">
                        <div className="ktheme-container">
                            <h2 className="ktheme-title">
                                {themeId === 'rgb-code' ? 'RGB Code' : 'RGB Select'} 테마 선택
                            </h2>
                            <div
                                className="theme-preview"
                                style={{
                                    background: selectedTheme
                                        ? `linear-gradient(90deg, ${selectedTheme.mainColor} 50%, ${selectedTheme.subColor} 50%)`
                                        : '#444',
                                }}
                            >
                                <div className="theme-name" style={{ color: textColor }}>
                                    {selectedTheme ? `Selected Theme: ${selectedTheme.name}` : 'Select a theme'}
                                </div>
                            </div>
                            <button className="select-button" onClick={() => setPopupVisible(true)}>
                                테마 선택
                            </button>

                            {popupVisible && (
                                <div className="theme-popup">
                                    <div className="theme-grid">
                                        {availableThemes.map((theme, index) => (
                                            <div
                                                key={index}
                                                className="theme-item"
                                                style={{
                                                    background: `linear-gradient(90deg, ${theme.mainColor} 50%, ${theme.subColor} 50%)`,
                                                }}
                                                onClick={() => handleThemeSelect(theme)}
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <KthemeFooter />
                </div>
            </div>
        </>
    );
};

export default KthemeRelease;
